import { formatInTimeZone } from "date-fns-tz";
import { produce } from "immer";
import { capitalize, isNil, orderBy } from "lodash";

import { EXPORT_TIME_FORMATS } from "~/constants/csv-export";

export const paginate = (list, page, pageSize) => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return list.slice(startIndex, endIndex);
};

export const sortAllRows = (rows, sortSetting) =>
  orderBy(rows, tableSort(sortSetting), sortSetting.sortDirection);

export const tableSort = sortSetting => {
  const { isTShirtSize, isPoi, isNumeric, sortDirection, columnToSort } =
    sortSetting;

  if (isTShirtSize) return avgRateSort(columnToSort);
  if (isNumeric) return numericSort(columnToSort, sortDirection);
  if (isPoi) return poiSort(columnToSort, sortDirection);

  return defaultSort(columnToSort, sortDirection);
};

export const defaultSort = columnToSort => row => {
  const value = row[columnToSort];
  return isNil(value) ? value : value?.toString().toLowerCase();
};

export const numericSort = columnToSort => row => parseFloat(row[columnToSort]);

export const poiSort = (columnToSort, sortDirection) => [
  row => {
    const order = sortDirection[0];
    const sendToBottom =
      isNil(row[columnToSort]) || row[columnToSort] === "N/A";
    return order === "asc" ? (sendToBottom ? 1 : 0) : sendToBottom ? -1 : 0;
  },
  row => (isNil(row.poiName) ? parseFloat(row.poi) : Infinity),
  row => row.poiName
];

const TSHIRT_ORDER_LOOKUP = {
  "N/A": -Infinity,
  None: -5,
  Small: -4,
  Medium: -3,
  Large: -2,
  Super: -1
};

export const avgRateSort = columnToSort => row => {
  const value = row[columnToSort] || "N/A";
  const numericValue = parseAvgRateText(value);
  return isNaN(numericValue) ? TSHIRT_ORDER_LOOKUP[value] : numericValue;
};

/**
 * Parses and processes a value, handling dates and numeric values.
 * If the value can be parsed into a valid Date object, it returns the Date.
 * If the value can be parsed into a numeric value, it returns the numeric value.
 * Otherwise, it returns the original value as is.
 *
 * @param {string} value - The value value to be parsed and processed.
 * @returns {Date|number|string} - Parsed Date, numeric value, or original value.
 */
export const parseValue = (value, isAvgRate) => {
  if (!isNaN(Date.parse(value))) return value;

  const numericValue = parseFloat(value);

  if (isAvgRate) return isNaN(numericValue) ? value : parseAvgRateText(value);

  return isNaN(value) ? value : numericValue;
};

export const removeDegreeSign = poi => poi?.toString()?.replace(/°/g, "");

export function formatNumber(value, col) {
  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue)) {
    return col?.significantDigits
      ? col?.onNull()?.toFixed(col.significantDigits)
      : col?.onNull();
  }

  if (parsedValue === -1 && col?.isWholeNum) return col?.onNull();

  return parsedValue.toFixed(col?.significantDigits ?? 0);
}

export function formatDate(
  value,
  col,
  timeFormat = EXPORT_TIME_FORMATS.EASY_READ,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  if (!value) return col?.onNull();

  const isoDate = new Date(value).toISOString();

  if (timeFormat === EXPORT_TIME_FORMATS.ISO_8601) {
    return isoDate;
  }

  return (
    '"' +
    formatInTimeZone(isoDate, col?.displayUtc ? "UTC" : timeZone, timeFormat) +
    '"'
  );
}

export function formatString(value) {
  return value ? capitalize(value) : "N/A";
}

export function formatIncline(row) {
  const { inclineMax, windSpeedMax } = row;
  const value =
    inclineMax && windSpeedMax ? `${inclineMax}° at ${windSpeedMax}` : "N/A";

  return value;
}

export const swapColumns = (arr, index1, index2) => {
  if (
    index1 < 0 ||
    index1 >= arr.length ||
    index2 < 0 ||
    index2 >= arr.length
  ) {
    return arr;
  }

  return produce(arr, draft => {
    [draft[index1], draft[index2]] = [draft[index2], draft[index1]];
  });
};

export const parseAvgRateText = value =>
  parseInt(value?.toString().replace(/[^\w]/g, ""));
