const { isNil } = require("lodash");

const {
  LeakRateMultiplier,
  TemperatureMultiplier,
  WindSpeedMultiplier,
  VolumeMultiplier
} = require("../constants/unit-constants");

const {
  intlFormatNumber,
  formatNumberToPrecision
} = require("./intl-format-number");

const updateWindSpeed = (windSpeed, unit, showUnit = true) => {
  if (isNil(windSpeed) || isNaN(windSpeed) || windSpeed < 0) return "N/A";

  const newSpeed = windSpeed
    ? Math.round(windSpeed * WindSpeedMultiplier[unit])
    : 0;

  return showUnit ? `${newSpeed?.toFixed()} ${unit}` : newSpeed?.toFixed();
};

const updateAvgRate = (value, unit) => {
  const parsedNum = parseFloat(value);
  if (isNaN(parsedNum) || parsedNum === 0) return "N/A";

  const average = convertGasUnit(parsedNum, unit);

  return `${average} ${unit}`;
};

const updateSensorTemperature = (sensorTemperature, unit) => {
  const converter = TemperatureMultiplier[unit];

  if (
    isNil(sensorTemperature) ||
    isNaN(sensorTemperature) ||
    isNil(converter) ||
    sensorTemperature <= -273
  )
    return "N/A";

  const temperature = converter(sensorTemperature);

  return `${temperature?.toFixed()} ${unit}`;
};

const convertGasUnit = (n, leakRateUnit, toFormat = true) => {
  if (isNil(n) || isNaN(n)) return "N/A";

  const result = n * LeakRateMultiplier[leakRateUnit];

  return toFormat ? intlFormatNumber(result) : result;
};

const updateVolume = (volume, unit, showUnits = true) => {
  if (isNil(volume) || isNaN(volume)) return "N/A";

  const result = volume * VolumeMultiplier[unit];

  if (showUnits) return `${formatNumberToPrecision(result)} ${unit}`;

  return `${formatNumberToPrecision(result)}`;
};

module.exports = {
  updateWindSpeed,
  updateAvgRate,
  updateSensorTemperature,
  updateVolume,
  convertGasUnit
};
