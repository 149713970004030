import { IconButton, MenuItem as MaterialMenuItem } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

import { hexToRgba } from "../utils";

import { cameraViews } from "./ImagePlayer";

export const ImagePlayerButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 3,
  marginLeft: theme.spacing(1)
}));

export const MenuItem = styled(MaterialMenuItem)(({ theme }) => ({
  fontSize: 14,
  display: "flex",
  alignItems: "center",
  "& > span": {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[400]
  },
  "& .icon": {
    width: 18,
    color: theme.palette.grey[400]
  },
  "&:hover": {
    background: `${theme.palette.grey[800]}66`
  }
}));

export const useStyles = makeStyles(
  ({ palette, shadows, spacing, typography }) => ({
    root: ({ disablePlayback }) => ({
      background: `${hexToRgba(palette.common.black, 0.8)}`,
      cursor: disablePlayback ? "default" : "pointer",
      boxShadow: shadows[2],
      border: `1px solid ${palette.grey[700]}`,
      borderRadius: 4,
      transition: "all 0.2s",
      overflow: "hidden",
      position: "relative",
      pointerEvents: "all",
      height: "100%",
      width: "100%",
      "&:hover": {
        boxShadow: shadows[6]
      }
    }),
    loading: {
      position: "absolute",
      display: "block",
      justifyContent: "center",
      width: "100%",
      top: 0,
      bottom: 0,
      color: palette.common.white
    },
    framesContainer: () => ({
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center"
    }),
    frame: ({ cameraView, loading }) => ({
      aspectRatio: "3/4",
      height: "100%",
      maxWidth: cameraView === cameraViews.both ? "50%" : "100%",
      position: "relative",
      display: "inline-flex",
      alignItems: "center",
      borderLeft: loading ? "none" : `0.5px solid ${palette.grey[600]}`,

      "&:last-of-type": {
        borderRight: `0.5px solid ${palette.grey[600]}`
      }
    }),
    absoluteFull: {
      width: "100%",
      aspectRatio: "3/4",
      display: "block",
      position: "absolute"
    },
    image: {
      width: "100%",
      aspectRatio: "3/4",
      display: "block"
    },
    imageError: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: spacing(1),
      fontSize: 12,
      color: palette.grey[400],
      background: `${palette.grey[800]}`
    },
    overlay: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      fontFamily: typography.fontFamily
    },
    headerDiv: {
      display: "contents"
    },
    bars: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      position: "relative"
    },
    overlayBar: {
      display: "block",
      transition: "opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1)",
      opacity: 0
    },
    topBar: {
      background: `linear-gradient(to bottom, ${palette.grey[900]}, transparent)`,
      padding: spacing(1.5, 2, 10, 2),
      fontWeight: typography.fontWeightLight,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: 14
    },
    bottomBar: {
      background: `linear-gradient(to top, ${palette.grey[900]}, transparent)`,
      padding: spacing(7.75, 1, 1, 0),
      display: "flex",
      alignItems: "center"
    },
    showBar: {
      opacity: 1
    },
    cameraName: {
      color: palette.common.white,
      display: "block",
      paddingTop: spacing(0.5),
      textShadow: `1px 1px 3px black`
    },
    timestamp: {
      fontSize: 12,
      color: palette.grey[200]
    },
    playPauseButton: {
      marginRight: spacing(2)
    },
    poiLabel: {
      fontSize: 12,
      color: palette.grey[300],
      textShadow: `1px 1px 3px black`,
      marginTop: spacing(0.5),
      display: "flex",
      alignItems: "center",

      "& .icon": {
        width: 16,
        marginRight: spacing(1),
        position: "relative",
        top: -1
      }
    },
    dialogPaper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "90vh",
      width: "100%",
      maxWidth: "100%",
      borderRadius: 5,
      background: "transparent",
      pointerEvents: "none",
      fontFamily: typography.fontFamily,
      overflow: "hidden",
      boxShadow: "none"
    },
    fullscreenDialogContent: {
      aspectRatio: "6/4",
      maxWidth: "100%",
      maxHeight: "100%",
      flexGrow: 1
    },
    cameraCompassWrapper: {
      position: `absolute`,
      margin: "0 50%",
      transform: `translateX(-50%)`,
      bottom: 30
    }
  })
);

export const useSliderStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    flexGrow: 1,
    marginRight: spacing(2)
  },
  thumb: ({ disablePlayback }) => ({
    backgroundColor: palette.common.white,
    display: disablePlayback ? "none" : "block",
    "&:hover": {
      boxShadow: `0 0 0 4px ${hexToRgba(palette.common.white, 0.1)}`
    },
    "&:active, &:focus": {
      boxShadow: `0 0 0 8px ${hexToRgba(palette.common.white, 0.2)}`
    },
    "&.Mui-disabled": {
      width: 8,
      height: 8,
      backgroundColor: palette.grey[400]
    }
  }),
  active: {},
  track: ({ disablePlayback }) => ({
    display: disablePlayback ? "none" : "inline-block",
    backgroundColor: palette.grey[100],
    height: 4,
    borderRadius: 2
  }),
  rail: ({ disablePlayback }) => ({
    backgroundColor: palette.grey[200],
    height: 4,
    borderRadius: 2,
    opacity: disablePlayback ? "0" : "0.25"
  })
}));

export const usePlayerMenuStyles = makeStyles(
  ({ palette, shadows, spacing, typography }) => ({
    menuTitle: {
      fontSize: 12,
      color: palette.grey[400],
      padding: spacing(0.5, 3, 1, 2),
      marginBottom: spacing(0.5),
      fontFamily: typography.fontFamily,
      borderBottom: `1px solid ${palette.grey[700]}AA`
    },
    paper: {
      background: `${palette.grey[800]}EE`,
      fontSize: 14,
      boxShadow: shadows[1]
    },
    list: {
      padding: spacing(1, 0)
    },
    menuItem: {
      display: "flex",
      justifyContent: "space-between",
      "& span": {
        display: "flex",
        alignItems: "center",
        color: palette.grey[400]
      }
    }
  })
);
