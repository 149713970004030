import { Switch } from "@mui/material";
import PropTypes from "prop-types";

import { LabelWrapper, StyledBox } from "../styled-components";

export const LabelSwitch = ({
  title,
  name,
  formik,
  labelWidth = "100%",
  rootWidth,
  fontSize = 14,
  ...props
}) => {
  const handleChange = event => {
    formik.handleChange(event);
  };

  const value =
    typeof formik.values[name] === "string"
      ? formik.values[name]?.trimStart()
      : formik.values[name];

  return (
    <StyledBox width={rootWidth}>
      <LabelWrapper variant="h4" fontSize={fontSize} width={labelWidth}>
        {title}
      </LabelWrapper>

      <Switch checked={value} onChange={handleChange} name={name} {...props} />
    </StyledBox>
  );
};

LabelSwitch.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.object.isRequired,
  labelWidth: PropTypes.string,
  rootWidth: PropTypes.string,
  fontSize: PropTypes.number
};
