const intlFormatNumber = (num, digits = 0, locale = "en-US") => {
  return Number(num).toLocaleString(locale, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  });
};

const formatNumberToPrecision = (num, digits = 2) => {
  if (Number.isInteger(num)) {
    return num.toString();
  }
  const formatted = num.toFixed(digits);
  return parseFloat(formatted).toString();
};

module.exports = {
  intlFormatNumber,
  formatNumberToPrecision
};
