import { cameraViewTypes } from "@kuva/image-constants";

import { MAX_NUMBER_OF_ALARMS } from "~/constants/alarms";

import BaseApi from "./BaseApi";

class AlarmAPI extends BaseApi {
  constructor() {
    const url = `${process.env.REACT_APP_KUVA_API_URL}/alarm/v1`;
    super(url);
  }

  get = (deviceId, alarmId = "", streamId = "") =>
    this.axiosInstance.get(`alarm/${deviceId}/${alarmId}?streamId=${streamId}`);

  getGifToDownload = (deviceId, alarmId = "", type = cameraViewTypes.SWIR) =>
    this.axiosInstance.get(`gif/${deviceId}/${alarmId}/${type}`);

  getByPagination = ({
    deviceId,
    streamId,
    page,
    sort,
    pagesize = MAX_NUMBER_OF_ALARMS,
    cancelToken,
    ...body
  }) => {
    return this.axiosInstance.get(`alarm`, {
      params: {
        ...body,
        ...(streamId ? { streamId } : { deviceId }),
        page,
        pagesize,
        sort: sort || "start DESC"
      },
      cancelToken
    });
  };

  getAlarmsPerMonth = ({ deviceId, streamId, month, year }) =>
    this.axiosInstance.get("alarmspermonth", {
      params: { month, year, ...(streamId ? { streamId } : { deviceId }) }
    });

  getAlarmByOrg = ({
    streamIds,
    deviceIds,
    pagesize = MAX_NUMBER_OF_ALARMS,
    orgId,
    page = 1,
    frames = false,
    sort = "start DESC",
    cancelToken
  }) => {
    const params = {
      orgId,
      pagesize,
      page,
      frames,
      sort
    };

    if (streamIds?.length) params.streamIds = streamIds;
    if (deviceIds?.length) params.deviceIds = deviceIds;

    return this.axiosInstance.get(`alarm`, {
      params,
      cancelToken
    });
  };

  getLatestAlarmPerDevice = ({ orgId, signal }) =>
    this.axiosInstance.get(`alarms/organizations/${orgId}/latest`, {
      signal
    });

  getLatestAlarmPerStream = ({ orgId, signal }) =>
    this.axiosInstance.get(
      `alarms/organizations/${orgId}/latest-stream-alarms`,
      {
        signal
      }
    );

  download = async ({ type = "csv", camera, ...rest }) =>
    this.axiosInstance.get(`/export/${type}/${camera ?? ""}`, {
      params: {
        ...rest
      }
    });

  getEmailNotificationSubscribersByOrg = orgId =>
    this.axiosInstance.get(`notification-configs/${orgId}`);

  getNotifList = () =>
    this.axiosInstance.get("alarmnotifications?type=organization");

  updateEmailNotification = body =>
    this.axiosInstance.patch("notification-configs", body);

  createEmailNotification = body =>
    this.axiosInstance.post("notification-configs", body);

  postFeedbackEmail = body => this.axiosInstance.post("feedback", body);
}

export default AlarmAPI.Instance();
