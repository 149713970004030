import orderBy from "lodash/orderBy";

import { parseValue, tableSort } from "../table-utils";

export const downloadCSV = (data, filename) => {
  const normalizedData = normalizeCsvData(data);
  const csvData = generateCSVData(normalizedData);

  if (!csvData) return csvData;

  createCsvLink(csvData, filename);

  return true;
};

export const createCsvLink = (csvData, filename) => {
  const element = document.createElement("a");
  element.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
  element.target = "_blank";
  element.download = filename;
  element.click();
};

const fieldOptions = {
  "quantResult.resultMB.avg": "avgLeakRate",
  "quantResult.resultMB.aggLeakRateClass": "avgTshirtSize",
  avgWindSpeed: "windSpeed",
  avgWindDirection: "windDirection",
  windSpeed: "wind_speed"
};

export const normalizeCsvData = ({
  rows,
  columns = [],
  sortSetting,
  timeFormat
}) => {
  const sortedRows = orderBy(
    rows,
    tableSort(sortSetting),
    sortSetting.sortDirection
  );

  return sortedRows.map(row => {
    const filteredRow = {};
    for (const col of columns) {
      if (col.columnVisibility) {
        const headerName =
          col.unitsOfMeasurement !== undefined
            ? `${col.headerName} (${col.unitsOfMeasurement})`
            : col.headerName;

        const field = fieldOptions[col.field] || col.field;

        const parsedValue = parseValue(row[field], field === "avgLeakRate");

        if (field === "inclineMax") {
          filteredRow[headerName] = col.formatForExport?.(row);
        } else if (field === "cameraName") {
          filteredRow[headerName] = parsedValue;
        } else {
          filteredRow[headerName] =
            col.formatForExport?.(parsedValue, col, timeFormat) ?? parsedValue;
        }
      }
    }
    return filteredRow;
  });
};

const generateCSVData = arrayObject => {
  if (!arrayObject) {
    return undefined;
  }

  const header = Object.keys(arrayObject[0]).join(",") + "\n";
  const data = arrayObject.map(obj => Object.values(obj).join(",")).join("\n");

  return header + data;
};
