import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { styled } from "@mui/styles";

import { DEFAULT_HOOP_SCALE, DEFAULT_POINT_SIZE } from "./constants";

const StyledPoint = styled("div")(({ left, top }) => ({
  position: "absolute",
  left: left,
  top: top,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: "translate(-50%, -50%)"
}));

export const LeakSourceIndicator = ({
  leakSourceCoordinates = [],
  img = null,
  isVisible = false
}) => {
  const [coords, setCoords] = useState([]);
  const [pointSize, setPointSize] = useState([]);
  const [hoopScale, setHoopScale] = useState(DEFAULT_HOOP_SCALE);

  const handleCoords = () => {
    if (!img?.current || isEmpty(leakSourceCoordinates)) return;

    const imgWidth = img.current.naturalWidth || 313;
    const imgHeight = img.current.naturalHeight || 410;

    const width = img.current.width;
    const height = img.current.height;

    const widthScale = width / imgWidth;
    const heightScale = height / imgHeight;

    setPointSize([
      DEFAULT_POINT_SIZE * widthScale,
      DEFAULT_POINT_SIZE * heightScale
    ]);

    setCoords([
      leakSourceCoordinates[0] * widthScale,
      leakSourceCoordinates[1] * heightScale
    ]);

    // Adjust hoop scale if image/frame width goes below 300px
    setHoopScale(width < 300 ? 0.5 : 1);
  };

  useEffect(() => {
    handleCoords();
    window.addEventListener("resize", handleCoords);

    return () => {
      window.removeEventListener("resize", handleCoords);
    };
  }, [leakSourceCoordinates, img]);

  if (
    !isVisible ||
    isEmpty(leakSourceCoordinates) ||
    coords.length === 0 ||
    pointSize.length === 0
  ) {
    return null;
  }

  return (
    <StyledPoint left={coords[0]} top={coords[1]}>
      {/* Outer Hoops */}
      <svg
        width={160 * hoopScale}
        height={160 * hoopScale}
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "absolute" }}
        aria-hidden="true"
      >
        <circle
          cx="80"
          cy="80"
          r="38.5"
          stroke="#FF5100"
          strokeOpacity="0.8"
          strokeWidth="3"
        />
        <circle
          cx="80"
          cy="80"
          r="78.5"
          stroke="#FF5100"
          strokeOpacity="0.6"
          strokeWidth="3"
        />
      </svg>

      {/* Center Point */}
      <svg
        width={pointSize[0]}
        height={pointSize[1]}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <circle cx="8" cy="8" r="8" fill="#FF5100" />
      </svg>
    </StyledPoint>
  );
};

LeakSourceIndicator.propTypes = {
  leakSourceCoordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
  img: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.instanceOf(HTMLImageElement)
    }),
    PropTypes.oneOf([null])
  ]).isRequired,
  isVisible: PropTypes.bool
};
